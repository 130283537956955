import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Content extends Component {
  label() {
    switch(this.props.document.type) {
      case "general": {
        return "success";
      }
      case "economica": {
        return "warning";
      }
      case "juridica": {
        return "alert";
      }
      default: {
        return "primary";
      }
    }
  }

  render() {
    return(
      <a href={this.props.document.url} target="_blank" rel="noopener noreferrer">
        <div className="cell">
          <div className="card result">
            <div className="card-section">
              <h5>
                <span className='label primary'>
                  {this.props.document.expediente}
                </span> {this.props.document.company_name}
              </h5>
              <h5>
                <span className={`label ${this.label()}`}>
                  {this.props.document.type}
                </span> {this.props.document.name} <span className='label primary'>
                  {this.props.document.date}
                </span>
              </h5>
            </div>
            <div className="card-divider">
              <p>
                {this.props.document.text}
              </p>
            </div>
          </div>
        </div>
      </a>
    )
  }
}

export default Content;
