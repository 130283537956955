import React, { useState, useEffect } from 'react';
import Content from './Content';

function Contents(props) {
  const [documents, setDocuments] = useState([])

  useEffect(() => {
    fetch(`https://api.ecuadorpapers.org/search?q=${props.term}&size=20`)
      .then(response => response.json())
      .then(data => {
        console.log(documents)
        setDocuments(data)
      })
  }, [props.term])

  return(
    <div className="result-list">
      {documents.map(doc => <Content document={doc} />)}
    </div>
  )
}

export default Contents;
