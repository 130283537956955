import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class Document extends Component {
  downloadLink(){
    if (this.props.document.broken) return this.props.document.original_url
    if (this.props.document.ocr) return this.props.document.ocr_url.slice(1)
    if (this.props.document.downloaded) return this.props.document.url.slice(1)
    return this.props.document.original_url
  }
  label() {
    switch(this.props.document.type) {
      case "general": {
        return "success";
      }
      case "economica": {
        return "warning";
      }
      case "juridica":{
        return "alert";
      }
      default: {
        return "primary";
      }
    }
  }
  render() {
    return(
      <tr>
        <td>
          <span className={ `label ${this.label()}` }>
            {this.props.document.type}
          </span>
        </td>
        <td>{this.props.document.name}</td>
        <td>
          {
            new Intl.DateTimeFormat('es-ES', {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit'
            }).format(new Date(this.props.document.date))
          }
        </td>
        <td>
          <a href={this.downloadLink()} target="_blank" rel="noopener noreferrer" >
            Descargar
            <FontAwesomeIcon
              className="btn-icon"
              icon="download"
              size="lg" />
          </a>
        </td>
      </tr>
    )
  }
}

export default Document;
