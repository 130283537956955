import React from 'react';
import { Query } from 'react-apollo';
import { gql } from 'apollo-boost';
import Company from '../components/Company.js'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const COMPANY_QUERY = gql`
  query Company($code: Int!){
    company(code: $code){
      id
      code
      name
      employees
      capital
      region
      province
      city
      sector
      activity
      documents{
        id
        code
        date
        name
        broken
        downloaded
        ocr
        original_url
        url
        ocr_url
        type
      }
      stocks{
        id
        code
        value
        type
        original_url
        url
        downloaded
        date
      }
    }
  }
`;

const CompanyQuery = ({ code }) => (
  <Query query={COMPANY_QUERY} variables={{ code }}>
    {({ loading, error, data}) => {
      if (loading) return(
        <p>Cargando... <FontAwesomeIcon icon="spinner" spin /></p>
      )
      if (error) return <p>Error en la consulta</p>;

      return <Company company={data.company} />
    }}
  </Query>
);

export default CompanyQuery;
