import React, { Component } from 'react';
import License from './License';

class Description extends Component {
  render() {
    return(
      <div>
        <p>
          Este sitio almacena documentos de registro de empresas de Ecuador, puedes consultar o descargar los documentos disponibles utilizando el buscador.
        </p>
        <p>
          La información contenida en esta web procede de registros públicos abiertos y está en el dominio público. Por tanto, no es material protegible de acuerdo a la legislación de propiedad intelectual del país de origen. La puesta a disposición pública de dichos contenidos se realiza con fines de investigación y educación y sin fines comerciales o de lucro.
        </p>
        <License />
      </div>
    )
  }
}

export default Description;
