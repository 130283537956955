import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class Stock extends Component {
  downloadLink(){
    return this.props.stock.downloaded ? this.props.stock.url : this.props.stock.original_url
  }
  render() {
    return(
      <tr>
        <td>
          <span className="label" >
            {this.props.stock.value}
          </span>
        </td>
        <td>{this.props.stock.type}</td>
        <td>
          {
            new Intl.DateTimeFormat('es-ES', {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit'
            }).format(new Date(this.props.stock.date))
          }
        </td>
        <td>
          <a href={this.downloadLink()} target="_blank" rel="noopener noreferrer" >
            Descargar
            <FontAwesomeIcon
              className="btn-icon"
              icon="download"
              size="lg" />
          </a>
        </td>
      </tr>
    )
  }
}

export default Stock;
