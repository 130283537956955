import React, { Component } from 'react';
import Documents from './Documents';
import Stocks from './Stocks';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

class Panel extends Component {
  render() {
    return(
      <Tabs>
        <TabList>
          <Tab>Documentos societarios</Tab>
          <Tab>Mercado de valores</Tab>
        </TabList>
        <TabPanel>
          <Documents documents={this.props.documents} />
        </TabPanel>
        <TabPanel>
          <Stocks stocks={this.props.stocks} />
        </TabPanel>
      </Tabs>
    )
  }
}

export default Panel;
