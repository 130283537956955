import React, { Component } from 'react';
import Result from './Result';

class Results extends Component {
  constructor(props) {
    super(props);
    this.handleScroll = this.handleScroll.bind(this)
  }
  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll(e) {
    if(
      window.innerHeight + document.documentElement.scrollTop >=
      document.documentElement.offsetHeight
    ) {
      this.props.onLoadMore();
    }
  }
  render() {
    return(
      <div className="result-list">
        {this.props.companies.edges.map(item => (
         <Result key={item.node.code} company={item.node} />
        ))}
      </div>
    )
  }
}

export default Results;
