import React, { Component } from 'react';
import Result from './Result';
import Details from './Details';
import Panel from './Panel';

class Company extends Component {
  componentDidMount(){
    document.title = `Ecuador papers | ${this.props.company.name}`
  }
  render(){
    return(
      <div>
        <Result company={this.props.company} />
        <Details company={this.props.company} />
        <Panel documents={this.props.company.documents} stocks={this.props.company.stocks} />
      </div>
    )
  }
}

export default Company;
