import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class Details extends Component {
  constructor(props){
    super(props);

    this.state = {
      company: this.props.company
    }
  }
  render() {
    return(
      <div className="cell">
        <div className="card">
          <div className="card-divider">
            <h5>Actividad económica</h5>
          </div>
          <div className="card-section">
            <p>
              { this.state.company.activity }
            </p>
          </div>
          <div className="card-section grid-x footer">
            <div className="cell medium-6">
              <FontAwesomeIcon icon="map-marker" size="lg" pull="left"/>
              { this.state.company.province } / { this.state.company.city }
            </div>
            <div className="cell medium-6">
              <FontAwesomeIcon icon="building" size="lg" pull="left"/>
              { this.state.company.sector }
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Details;
