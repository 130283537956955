import React, { Component } from 'react';
import NumberFormat from 'react-number-format';
import { Link } from 'react-router-dom';

class Result extends Component {
  render(){
    return(
      <Link to={`/company/${this.props.company.code}`}>
        <div className="cell">
          <div className="card result">
            <div className="card-section">
              <h5>{this.props.company.name}</h5>
            </div>
            <div className="card-divider">
              <ul className="details">
                <li><span><b>Expediente N°:</b> {this.props.company.code}</span></li>
                <li><span><b>Capital registrado:</b> <NumberFormat value={this.props.company.capital} displayType={'text'} thousandSeparator={true} prefix={'$'} /></span></li>
                <li><span><b>Personal:</b> {this.props.company.employees}</span></li>
              </ul>
            </div>
          </div>
        </div>
      </Link>
    )
  }
}

export default Result;
