import React, { Component } from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import CompanySearchResults from '../services/CompanySearchResults'
import Contents from './Contents'

class SearchForm extends Component {
  constructor(props){
    super(props);
    this.state = {
      term: ""
    }
    this.handleChange = this.handleChange.bind(this);
  }
  handleChange(e){
    this.setState({
      term: e.target.value
    });
  }
  render() {
    return (
      <div>
        <div className="input-group">
          <input className="input-group-field"
            id="search_input"
            placeholder="Número de expediente o nombre"
            onChange={this.handleChange}
            type="search" />
          <div className="input-group-button">
            <button className="button" id="search_btn">Buscar</button>
          </div>
        </div>
        <Tabs>
          <TabList>
            <Tab>Empresas</Tab>
            <Tab>Documentos</Tab>
          </TabList>
          <TabPanel>
            {
              CompanySearchResults({term: this.state.term})
            }
          </TabPanel>
          <TabPanel>
              <Contents term={this.state.term} />
          </TabPanel>
        </Tabs>
      </div>
    )
  }
}

export default SearchForm;
