import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Menu extends Component{
  render(){
    return(
      <ul className="menu align-right">
        <li>
          <Link to="/">
            <span>Búsquedas</span>
            <i className="fi-list"></i>
          </Link>
        </li>
      </ul>
    )
  }
}

export default Menu;
