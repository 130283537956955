import React, { Component } from 'react';

class License extends Component{
  render(){
    return(
      <p>
        <span className="license">
          Datos hasta 30/12/2020
        </span>
      </p>
    )
  }
}

export default License;
