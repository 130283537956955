import React, { Component } from 'react';
import Stock from './Stock';

class Stocks extends Component {
  render() {
    return(
      <table className="stocks stack hover">
        <thead>
          <tr>
            <th>Tipo</th>
            <th>Nombre</th>
            <th>Fecha</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {this.props.stocks.map(item => (
            <Stock key={item.id} stock={item} />
          ))}
        </tbody>
      </table>
    )
  }
}

export default Stocks;
