import React, { Component } from 'react';
import SearchForm from './components/SearchForm';
import Description from './components/Description';
import Menu from './components/Menu';
import FetchCompany from './components/FetchCompany';
import { ApolloProvider } from "react-apollo";
import Client from './services/Client';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import './App.css';
import { library } from '@fortawesome/fontawesome-svg-core'
import { faMapMarker, faBuilding, faDownload, faSpinner } from '@fortawesome/free-solid-svg-icons'

library.add(faMapMarker, faBuilding, faDownload, faSpinner)

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  render() {
    return(
      <Router>
        <ApolloProvider client={Client}>
          <div className="grid-container">
            <div className="grid-x menu">
              <div className="cell medium-3">
                <h1 className="home__heading">Observatorio</h1>
              </div>
              <div className="cell medium-9">
                <Menu />
              </div>
            </div>
            <div className="grid-x container">
              <div className="cell medium-3">
                <Description />
              </div>
              <div className="cell medium-9">
                <Route path="/" exact component={SearchForm} />
                <Route path="/company/:code" component={FetchCompany} />
              </div>
            </div>
          </div>
        </ApolloProvider>
      </Router>
    )
  }
}

export default App;
