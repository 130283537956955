import React, { Component } from 'react';
import CompanyQuery from '../services/CompanyQuery'

class CompanyDetails extends Component {
  constructor(props){
    super(props);

    this.state = {
      company_id: parseInt(props.match.params.code)
    }
  }
  render(){
    return(
      <div>
        { 
          CompanyQuery({code: this.state.company_id})
        }
      </div>
    )
  }
}

export default CompanyDetails;
