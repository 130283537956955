import React from 'react';
import { Query } from 'react-apollo';
import { gql } from 'apollo-boost';
import Results from '../components/Results'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const SEARCH_QUERY = gql`
  query SearhResult($term: String!, $cursor: String){
    search(term: $term, first: 10, after: $cursor){
      edges {
        node {
          id
          name
          code
          employees
          capital
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;

const CompanySearchResults = ({ term }) => (
  <Query query={SEARCH_QUERY} variables={{ term }}>
    {({data: {search: result} , loading, error, fetchMore}) => {
      if (loading) return(
        <p>
          Buscando... <FontAwesomeIcon icon="spinner" spin />
        </p>
      )
      if (error) return <p>Error en la consulta</p>;

      return ( 
      <Results
        companies={result || []}
        onLoadMore={() =>
          fetchMore({
            variables: {
              cursor: result.pageInfo.endCursor
            },
            updateQuery: (previousResult, { fetchMoreResult }) => {
              const newEdges = fetchMoreResult.search.edges;
              const pageInfo = fetchMoreResult.search.pageInfo;

              return newEdges.length
                ? {
                  search: {
                    __typename: previousResult.search.__typename,
                    edges: [...previousResult.search.edges, ...newEdges],
                    pageInfo
                  }
                }
                : previousResult;
            }
          })
        }
      />
      )
    }}
  </Query>
);

export default CompanySearchResults;
