import React, { Component } from 'react';
import Document from './Document';

class Documents extends Component {
  render() {
    return(
      <table className="documents stack hover">
        <thead>
          <tr>
            <th>Tipo</th>
            <th>Nombre</th>
            <th>Fecha</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {this.props.documents.map(item => (
            <Document key={item.id} document={item} />
          ))}
        </tbody>
      </table>
    )
  }
}

export default Documents;
